import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        We're tinkering behind the scenes to bring you something awesome.
        </p>
        <p>
        Hang tight while our engineers work their magic (or maybe just find that one missing screw)!
        </p>
        <a
          className="App-link"
          href="https://www.boredpanda.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Good things come to those who wait... and wait... and wait... We're almost there 
        </a>
      </header>
    </div>
  );
}

export default App;
